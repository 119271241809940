<template>
<span>
  <router-link v-if="stype=='link'" class="page-numbers" :to="linktopage">{{shpage}}</router-link>
  <span v-if="stype=='curent'" aria-current="page" class="page-numbers current">{{shpage}}</span>
  <span v-if="stype=='dots'" class="page-numbers dots">…</span>

</span>
</template>

<script>
export default {
name : "paginatorButtons",
props : ['type','page'],
computed : {


linktopage() {

//console.log('linktopage()')
//console.log(this.$route.params)

if(  this.$route.params.page)
{
  //console.log('linktopage return object')
return {
name : this.$route.name,
params : {
   page : this.shpage

      }
   }

  }



//console.log(`${this.$route.path}/${this.shpage}`)
return  `${this.$route.path}/${this.shpage}`

}

},
data() {

return {
stype : this.type,
shpage : this.page


}

},
mounted() {
//console.log(this.$route)
//console.log(this.$router)

//console.log(`buttons mounted ${this.type}`)

},
watch : {

type(n,o) {

//console.log(`paginatorButtons : type changed to ${n}`)
  this.stype = n
},

page(n,o) {

//console.log(`paginatorButtons : page changed to ${n}`)
  this.shpage = n
      }


}

}
</script>
