<template>
<div>

  <div id="main" class="site-main">

<div id="main-content" class="main-content">

<mainslider />
<div id="page-title" class="page-title-block page-title-style-1 has-background-image" style="background-image: url(/assets/backgrounds/title/06.jpg);"><div class="container"><div class="page-title-title"><h1 style="">  Новости</h1></div></div></div>

<div class="block-content">
<newsblock1 count=6 :page="showpage" />

				<article id="post-2563" class="post-2563 page type-page status-publish hentry">

					<div class="entry-content">
						<p></p>
					</div><!-- .entry-content -->

				</article><!-- #post-## -->

			</div>


		</div>
	</div>
</div>

</div><!-- #main-content -->

</div>
</div>
</template>



<script>
import mainslider from "@/components/mainslider.vue"
import newsblock1 from  "@/components/news/newsblock1.vue"
  import {rollup} from "@/lib/utils.js"

export default {
name : 'allnews',
components : {mainslider,newsblock1},
mounted() {

  console.log(this.$router.params)
  console.log(`news.all`)
},
data() {

  let showpage = 1
    console.log(this.$route.params)

    if(this.$route.params.page)
    {
      showpage = this.$route.params.page
    }

  return {
      showpage : showpage
       }
},
mounted() {
rollup()

}

}
</script>
