<template>
  <div v-if="pageend !=1" class="sc-pagination">


		<router-link v-if="page >1" class="prev page-numbers" :to="prevpage">Предыдущий</router-link>

<paginatorButtons :type="`${getfistpagetype()}`" page=1 />


  <paginatorButtons v-for="page in schema" :type="page.type" :page="page.page" />



    <paginatorButtons :type="`${getlastpagetype()}`" :page="pageend" />



<router-link v-if="page < pageend" class="next page-numbers" :to="nextpage">Следующий</router-link>

</div>


</template>

<script>
import paginatorButtons from "@/components/paginator/paginator-buttons.vue"
import pagination from "@/lib/pagination.js"
export default {
name : 'Paginator',
components : {paginatorButtons},
props : ['totalpages','curpage'],
computed : {

nextpage(){

if(this.$route.params)
  if(this.$route.params.page)
    {
return {
  name : this.$route.name,
  params : {page : parseInt(this.page)+1}

        }
      }

  return {
path : `${this.$route.path}/${parseInt(this.page)+1}`,


  }

},
prevpage() {


  if(this.$route.params)
    if(this.$route.params.page)
      {
  return {
    name : this.$route.name,
    params : {page : parseInt(this.page)-1}

          }
        }

    return {
  path : `${this.$route.path}/${parseInt(this.page)-1}`,


    }


}


},
data() {

let page = 1
if(this.curpage)
   page = this.curpage



return {
pageend : this.totalpages,
page,
maxelements : 8,
schema : pagination(this.curpage,this.totalpages)



    }

},
mounted() {
//  console.log('paginator : ')
// console.log(`${this.pageend} page curent ${this.page}`)
this.schema = pagination(this.page,this.pageend)

//console.log('schema data')
//console.log(this.schema)

},
methods : {
createtabs() {
let j = this.maxelements
  if(j > this.pageend)
   j=this.pageend



},
getfistpagetype(){

if(this.page != 1)
  return 'link'

  return 'curent'

},
getlastpagetype()
{
  if(this.page != this.pageend)
    return 'link'

    return 'curent'


}



},
watch : {

totalpages : function(n,o)
{
 console.log("paginator : pagecount changed "+n)
 console.log("paginator cur page : "+this.page)
  this.pageend = parseInt(n)
  this.schema = pagination(this.page,parseInt(this.pageend))
  console.log(this.schema)

},
$route(n,o) {

},
curpage(n,o) {

this.page = parseInt(n)
this.schema = pagination(this.page,this.pageend)
console.log("paginator.vue change curpage")
}

}


}
</script>
