<template>
<div>

  <div class="container">
		<div class="panel row">
			<div class="panel-center col-xs-12">
				<div class="blog blog-style-default clearfix ">
	<article v-for="news in newsitems"  :id="news.id" :class="`rounded-corners default-background post-${news.id} post type-post status-publish format-standard has-post-thumbnail hentry category-events`">
				<div class="item-post-container">
			<div class="item-post clearfix">


				<div class="post-image">

													<router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}" class="default"><img width="540" height="390" :src="news.imagesrcmedium" class="img-responsive wp-post-image" alt="WhatsApp Image 2022-04-07 at 14.18.13" /></router-link>


				</div>
				<div class="post-text">
					<header class="entry-header">

						<div class="entry-title"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}" rel="bookmark">	<div v-html="news.title.rendered"></div></router-link></div>
						<div class="entry-meta">
															<span class="tag-links">
																													<router-link :to="`/rubricator/${news.categories[0]}`" :title="`Посмотреть все посты в ${news.categoryname}`">{{news.categoryname}}</router-link>
																	</span>
													</div>
						<!-- .entry-meta -->
					</header>
					<!-- .entry-header -->

											<div class="entry-content">
                    <div v-html="news.excerpt.rendered"></div>
											</div>

					<div class="clearfix entry-info">
													<span class="entry-date">{{news.postdateformated}}</span>																			<span class="read-more-link"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}"> Далее</router-link></span>
											</div>
				</div>
			</div>
		</div>
	</article><!-- #post-14941 -->





</div>
<paginator :curpage="showpage" :totalpages="pagestotal" />
</div>

</div>

</div>
</div>
</template>

<script>
import axios from "axios"
import "datejs"
import {getwpposts} from "@/lib/utils.js"
import {rollup} from "@/lib/utils.js"
import paginator from "@/components/paginator.vue"
export default {
name : "newsblock1",
components : {paginator},
props : [
'category','count','page', 'totalpages'
    ],

data() {
  let showpage=1

  if(this.page)
  {
    showpage = this.page
  }

let pagestotal = 1
if(this.totalpages)
  pagestotal = this.totalpages

return {
newsitems : [],
onpagecount : 6,
showpage,
pagestotal
 }

},
mounted() {
rollup()
this.getnews()

},
methods : {

async getnews()  {

//console.log("Getting news")


this.newsitems = await getwpposts('any',this.onpagecount,this.showpage)

//console.log('newsblock1 : ')
//console.log(this.newsitems[0].totalpages)

if(this.newsitems[0])
  if(this.newsitems[0].totalpages)
    this.pagestotal = this.newsitems[0].totalpages

rollup()
    }




},
watch : {

$route()
{

//  console.log(" newsblock1.vue : route changed")
//  console.log(this.$route)

  if(this.$route.params)
     if(this.$route.params.page)
       {
         this.showpage = this.$route.params.page
          this.getnews()
          rollup()
       }

}

}

}

</script>
