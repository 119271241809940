function pagination(c, m) {
    var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

const tmp = []

rangeWithDots.forEach((item, i) => {

if( (item == c) &&(item != 1) && (item != m))
  {
    tmp.push({page : item, type : 'curent'})
    return
  }
if( (item != 1) && (item != m))
tmp.push({page : item, type : 'link'})


});


    return tmp;
}

export default pagination
